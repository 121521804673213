import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Form"/>
    <section>
      <h1>Leave me a Message</h1>

      <div className="row mt-4">
        <div className="content col-12 col-lg-9">
          <form action="https://formspree.io/f/mjvpdbko" method="POST">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <input type="text" name="name" className="form-control" required={true} placeholder="Name *"/>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <input type="email" name="email" className="form-control" required={true} placeholder="Email *"/>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="form-group">
                  <input type="text" name="phone" className="form-control" placeholder="Phone"/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input type="text" name="subject" className="form-control" required={true} placeholder="Subject *"/>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea name="message" className="form-control" id="form-input-message" placeholder="Message"/>
                </div>
              </div>
            </div>
            <div className="form-group">
              <input type="submit" className="btn btn-primary" value="Send"/>
            </div>
          </form>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage
